import { ui } from "@clientio/rappid";
export class ContextToolbarService {
    contextElement;
    createContextMenu(node, parentElement, lastX, lastY) {
        this.contextElement = node;
        const contextToolbar = new ui.ContextToolbar({
            target: { x: lastX, y: lastY },
            vertical: true,
            anchor: 'top',
            scale: 0.9,
            tools: [
                { action: 'addChild', content: 'Add Child' },
                { action: 'delete', content: 'Delete Entity' },
                { action: 'addInstance', content: 'Add Instance' },
                { action: 'merge', content: 'Merge...' },
                { action: 'move', content: 'Move...' },
                { action: 'copy', content: 'Copy...' },
                { action: 'makeAttr', content: 'Make Attribute...' },
                // {action: 'showRelations', content: 'Show Relationships'},
                { action: 'levelUp', content: 'Go to Parent' },
                { action: 'showOnly', content: 'Show as Root' },
                { action: 'generateFN', content: 'Generate Requirements/Stories/Features' },
                { action: 'generateQA', content: 'Generate Quality Attribute Requirements' },
            ],
        });
        contextToolbar.render();
        return contextToolbar;
    }
}
