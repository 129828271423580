import { dia, ui, shapes } from '@clientio/rappid';
import { createXrefLink } from "@/views/Behaviour/JointJSGraph/CustomShapes/customShapeFunctions";
// The Paper options object accepts this viewport: function
// This should only be used when async is true
function viewport(view) {
    const model = view.model;
    if (!model || !model.getBBox) {
        // something has a bad model, just render it
        return true;
    }
    const bbox = model.getBBox();
    const viewportRect = this.scroller.getVisibleArea();
    if (model.isLink()) {
        // vertical/horizontal links have zero width/height
        // we need to manually inflate the bounding box
        bbox.inflate(1);
    }
    return !!viewportRect.intersect(bbox);
}
export class PaperService {
    paper;
    scroller;
    createPaper(graph) {
        const defaultLink = createXrefLink(null, null, 'orange');
        this.paper = new dia.Paper({
            model: graph,
            background: {
                color: 'rgba(0, 0, 0, 0)',
            },
            width: 1600,
            height: 600,
            gridSize: 5,
            cellViewNamespace: shapes,
            frozen: false,
            async: false,
            interactive: false,
            labelsLayer: true,
            clickThreshold: 10,
            defaultLink,
        });
        // MUST HAVE SCROLLER TO WORK IN VUE
        this.scroller = new ui.PaperScroller({
            paper: this.paper,
            autoResizePaper: true,
            cursor: 'grab',
            scrollWhileDragging: true,
            inertia: true,
        });
        this.scroller.render();
        return { paper: this.paper, scroller: this.scroller };
    }
}
