import vSelect from 'vue-select/dist/vue-select';
import Fuse from 'fuse.js';
import { ref, toRef } from '@vue/composition-api';
export default {
    components: { vSelect },
    props: {
        keys: { type: Array, default: () => ['name'] },
        displayProp: { type: String, default: () => 'name' },
        maxResults: { type: Number, default: () => 9 },
        searchList: Array,
    },
    setup(props, context) {
        const searchBox = ref(null);
        const searchTextRef = toRef(props, 'searchText');
        const searchResults = ref([]);
        function focusSearchResult(result) {
            if (result) {
                context.emit('resultClicked', result);
            }
        }
        function searchInput(input) {
            searchTextRef.value = input;
            if (searchTextRef.value.length < 1)
                return;
            context.emit('search', input);
            const options = {
                includeScore: true,
                keys: props.keys,
            };
            const list = props.searchList;
            const fuse = new Fuse(list, options);
            const results = fuse.search(input);
            searchResults.value = results.filter((e, i) => i < props.maxResults).map(e => e.item).slice();
        }
        const OpenIndicator = {
            render: createElement => createElement('feather-icon', {
                props: { icon: 'SearchIcon' },
                style: 'transform: rotate(0) !important;',
            }),
        };
        return {
            // search
            searchTextRef,
            searchResults,
            focusSearchResult,
            searchInput,
            searchBox,
            OpenIndicator,
        };
    },
};
