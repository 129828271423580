import { onMounted, ref } from '@vue/composition-api';
import { dia, format, g, layout, shapes, ui } from "@clientio/rappid";
import { PaperService } from "@/components/Generic/Graph/services/paperService";
import jsPDF from "jspdf";
// by convention, composable function names start with "use"
export function useJointJs(canvas) {
    // state encapsulated and managed by the composable
    const bulkSelectedNodes = ref([]);
    const graph = new dia.Graph({}, { cellNamespace: shapes });
    const paperService = new PaperService();
    const { paper, scroller } = paperService.createPaper(graph);
    // Drag Select box
    const selection = new ui.Selection({
        paper,
        // allowCellInteraction: true, // JointJS+ 3.7 feature
    });
    function zoomView(paperScroller, evt, x, y, delta) {
        evt.preventDefault();
        paperScroller.zoom(delta * 0.1, {
            min: 0.15, max: 1, grid: 0.1, ox: x, oy: y,
        });
    }
    // Remove default tools
    selection.removeHandle('rotate');
    selection.removeHandle('resize');
    selection.removeHandle('link');
    selection.changeHandle('remove', {
        name: 'remove',
        position: ui.Selection.HandlePosition.SE,
        events: { pointerdown: null },
    });
    // --- EVENTS ---
    selection.on('action:remove:pointerup', evt => {
        evt.stopPropagation();
        selection.collection.reset([]);
    });
    // Enable panning when clicked on background
    paper.on('blank:pointerdown', evt => {
        if (evt.originalEvent.ctrlKey)
            selection.startSelecting(evt);
        else
            scroller.startPanning(evt);
    });
    // Enable zoom/scale on scroll
    paper.on('blank:mousewheel', (evt, x, y, delta) => {
        evt.preventDefault();
        zoomView(scroller, evt, x, y, delta);
    });
    // Prevent annoying scrolling when accidentally using mousewheel on a cell
    paper.on('cell:mousewheel', (cellView, evt, x, y, delta) => {
        evt.preventDefault();
        zoomView(scroller, evt, x, y, delta);
    });
    // -- Keyboard setup
    const keyboard = new ui.Keyboard({
        filter: (evt, keyboard) => {
            const { target } = evt;
            if (target.tagName === 'INPUT' && target.type === 'range') {
                // Prevent range inputs from consuming events.
                return true;
            }
            // Otherwise return the default behaviour
            return !keyboard.isConsumerElement(evt);
        },
    });
    const cellHighlighter = {
        highlighter: {
            name: 'stroke',
            options: {
                padding: 10,
                rx: 10,
                ry: 10,
                attrs: {
                    stroke: 'cornflowerblue',
                    'stroke-width': 3,
                },
            },
        },
    };
    keyboard.on('keyup:left keyup:right keyup:up keyup:down', evt => {
        if (evt.type !== 'keyup')
            return;
        // Deselect all other nodes on the graph
        // Select node
        const currentCellView = paper.findViewByModel(bulkSelectedNodes.value[0]).getBBox();
        const { sx, sy } = {
            sx: currentCellView.x,
            sy: currentCellView.y,
        };
        let closest = null;
        let nearestDist = 99999;
        paper.findViewsInArea(paper.getArea()).forEach(node => {
            if (!(evt.shiftKey || evt.ctrlKey)) {
                node.unhighlight(null, cellHighlighter);
            }
            const { x, y } = node.getBBox();
            if ((evt.key === 'ArrowLeft' && x < sx)
                || (evt.key === 'ArrowUp' && y < sy)
                || (evt.key === 'ArrowDown' && y > sy)
                || (evt.key === 'ArrowRight' && x > sx)) {
                const dist = Math.abs(y - sy) + Math.abs(x - sx);
                if (dist < nearestDist) {
                    closest = node;
                    nearestDist = dist;
                }
            }
        });
        if (closest) {
            if (!(evt.shiftKey || evt.ctrlKey)) {
                bulkSelectedNodes.value = [];
            }
            bulkSelectedNodes.value.push(closest.model.id);
            closest.highlight(null, cellHighlighter);
            scroller.scrollToElement(closest.model, { animation: { duration: 200 } });
            const cells = bulkSelectedNodes.value.map(n => graph.getCell(n));
            selection.collection.reset(cells);
        }
        evt.preventDefault();
    });
    // Navigator/Preview element
    const nav = new ui.Navigator({
        paperScroller: scroller,
        width: 300,
        height: 200,
        padding: 10,
        zoomOptions: { max: 1, min: 0.15 },
    });
    nav.render();
    // Define the layout for the graph, distance between nodes, parents, etc.
    const graphLayout = new layout.TreeLayout({
        graph,
        parentGap: 80,
        siblingGap: 80,
        direction: 'B',
    });
    // a composable can also hook into its owner component's
    // lifecycle to set up and teardown side effects.
    onMounted(() => {
        canvas.value.appendChild(scroller.el);
        canvas.value.appendChild(nav.el);
        scroller.center();
        paper.unfreeze();
        paper.scale(0.85);
    });
    function exportPdf() {
        // PDF
        const backgroundColor = '#F3F7F6';
        const pageWidth = 841.89;
        const pageHeight = 595.28;
        const pageMargin = 20;
        // eslint-disable-next-line new-cap
        const doc = new jsPDF('l', 'pt', [pageWidth, pageHeight]);
        const imageMargin = 2;
        const y = 50;
        const exportOptions = {
            padding: 10,
            useComputedStyles: true,
            backgroundColor,
            size: '2x',
        };
        doc.text(document.title, pageWidth / 2, y / 2, { align: 'center' });
        format.toCanvas(paper, canvas => {
            // Page 1.
            const imageRect = new g.Rect(0, 0, canvas.width, canvas.height);
            const maxImageRect = new g.Rect(0, 0, pageWidth - 2 * pageMargin, pageHeight - y - pageMargin);
            const scale = maxImageRect.maxRectUniformScaleToFit(imageRect, new g.Point(0, 0));
            const width = canvas.width * scale;
            const height = canvas.height * scale;
            const x = (pageWidth - width) / 2;
            // draw a rectangle around the diagram
            doc.setDrawColor(211, 211, 211);
            doc.setFillColor(backgroundColor);
            doc.setLineWidth(1);
            doc.roundedRect(x - imageMargin, y - imageMargin, width + 2 * imageMargin, height + 2 * imageMargin, 2, 2, 'FD');
            // draw the diagram from the canvas
            doc.addImage(canvas, 'JPEG', x, y, width, height);
            // download the PDF
            doc.save('diagram.pdf');
        }, { ...exportOptions });
    }
    const exporters = {
        pdf: exportPdf
    };
    // expose managed state as return value
    return { graph, paper, nav, scroller, selection, keyboard, bulkSelectedNodes, graphLayout, exporters };
}
