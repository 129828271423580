<template>
  <div class="canvas-container">
    <div class="h-100 w-100">
    <div class="bn-toolbar">
      <b-dd variant="outline-secondary" no-caret>
        <template #button-content>
          <feather-icon icon="MenuIcon" />
        </template>
        <b-dropdown-item @click="goHome">
          Home
        </b-dropdown-item>
      </b-dd>
      <b-button variant="outline-secondary" title="Refresh" :disabled="isGraphLoadingStatus" @click="refreshClicked">
        <b-spinner v-if="isGraphLoadingStatus" small variant="primary" label="Loading..." />
        <span v-else>
          <feather-icon icon="RefreshCwIcon" />
        </span>
      </b-button>
      <fuse-search-box :search-list="searchList" @search="i => searchText = i"
                       @resultClicked="onSearchResultClicked"
      />
      <b-button variant="primary" type="button" role="button" @click="doSearch">
        Search
      </b-button>
      <b-button variant="secondary" type="button" role="button" title="Open Ontology Explorer Page"
                @click="goToTreeview">
        Explorer
        <feather-icon icon="ExternalLinkIcon" />
      </b-button>
    </div>
    <div ref="canvas" :class="canvasClass" />
    <div v-if="selectedEntity2">
      <ShowComponentModal :component="selectedEntity2" />
    </div>
  </div>
  </div>
</template>

<script>
import AddEntityModal from '@/components/Domain/Modals/AddEntity.vue'
import AddInstanceModal from '@/components/Domain/Modals/AddInstance.vue'
import DeleteEntityModal from '@/components/Domain/Modals/DeleteEntity.vue'
import GenerateRequirementsFn from '@/components/Domain/Modals/GenerateRequirementsFn.vue'
import GenerateQualityAttributeRequirements from '@/components/Domain/Modals/GenerateQualityAttributeRequirements.vue'
import AllocateFnModal from '@/components/Domain/Modals/AllocateFn.vue'
import ShowComponentModal from '@/components/Domain/Modals/Context/ShowComponentModal.vue'
import axiosIns from '@/libs/axios'
import MergeEntityModal from '@/components/Domain/Modals/MergeEntityModal.vue'
import MoveEntityModal from '@/components/Domain/Modals/MoveEntityModal.vue'
import MakeEntityAttributeModal from '@/components/Domain/Modals/MakeEntityAttributeModal.vue'
import CopyEntityModal from '@/components/Domain/Modals/CopyEntityModal.vue'
import ImportSubtreeModal from '@/components/Domain/Modals/Import_Subtree.vue'
import ImportStaticUML from '@/components/Domain/Modals/Import_Static_UML.vue'
import Ripple from 'vue-ripple-directive'
import { v4 as uuidv4 } from 'uuid'
import { useRouter } from '@core/utils/utils'
import {
  computed,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from '@vue/composition-api'
import store from '@/store'
import { useWebWorkerFn } from '@vueuse/core'
import { useJointJs } from '@/components/Generic/Graph/useJointJs'
import {
  shapes, ui, util, layout,
} from '@clientio/rappid'
import { ContextToolbarService } from '@/components/Domain/jointOntology/contextToolbarService'
import { MoveMenuService } from '@/components/Domain/jointOntology/moveMenuService'
import { getChildren } from '@/views/Behaviour/JointJSGraph/behaviourJointJSFunctions'
import { HaloExpandService } from '@/views/Behaviour/JointJSGraph/services/haloExpandService'
import { HaloService } from '@/views/Behaviour/JointJSGraph/services/haloService'
import FuseSearchBox from '@core/components/fuse-search-box/FuseSearchBox.vue'
import coreService from '@/libs/api-services/core-service'
import moment from 'moment/moment'
import FileSaver from 'file-saver'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  shapeNamespace,
  createDemoShapes,
  createLink,
  createClassNode,
} from './umlShapes'

// eslint-disable-next-line import/no-extraneous-dependencies
// const FileSaver = require('file-saver')

export default {
  name: 'ClassDiagramJoint',
  components: {
    FuseSearchBox,
    ShowComponentModal,
  },
  setup(props, context) {
    const HEADER_NODE_PAGE_SIZE = 20
    const { route, router } = useRouter()
    const routeParams = computed(() => route.value.params)
    const routeQuery = computed(() => route.value.query)
    const canvas = ref(null) // Element ref
    const canvasClass = ref('canvas')
    const isGraphLoadingStatus = ref(false)
    const hiddenNodeIds = ref([])
    const compositionTree = ref([])
    const nodeMap = ref({})
    const nodes = ref([])
    // searchList combines already loaded nodes with the results of a db search query
    const searchList = ref([])
    const searchText = ref('')
    const links = ref([])
    const {
      graph, paper, nav, scroller, selection, keyboard, bulkSelectedNodes, exporters,
    } = useJointJs(canvas)
    const graphLayout = new layout.TreeLayout({
      graph,
      x: 0,
      y: 0,
      width: 1600,
      height: 800,
      gravityCenter: { x: 300, y: 200 },
      charge: 180,
      linkDistance: 130,
    })
    const haloService = new HaloService()
    const selectedEntity = computed(() => (bulkSelectedNodes.value?.length > 0 ? bulkSelectedNodes.value[0] : null))
    const selectedEntity2 = computed(() => store.state.domainModel.selected_entity2)
    const treeLayoutView = new ui.TreeLayoutView({
      paper,
      model: graphLayout,
      validateConnection: (element, candidateParent) => !!candidateParent,
      validatePosition: () => false,
      reconnectElements: (elements, parent, evt) => {
        handleDragDropNodes(elements, parent)
      },
    })

    keyboard.on('ctrl+/', evt => {
      evt.preventDefault()
      nextTick(() => {
        try {
          const ele = document.querySelector('#bn-search-dropdown .vs__search')
          ele.select()
          ele.focus()
          // throws exceptions even though it works
          // eslint-disable-next-line no-empty
        } catch (e) {
          console.error(e)
        }
      })
    })

    // Popup a menu when dragging a node and dropping using
    // the jointJs TreeLayoutView tool (big dots show where to move)
    function handleDragDropNodes(elements, parent) {
      // elements is an array because of multiselect
      const dragElement = elements[0]
      if (!dragElement) return
      const moveMenuService = new MoveMenuService()
      const { x, y } = paper.localToClientPoint(parent.position())
      const menu = moveMenuService.createMoveMenu(dragElement, x, y)
      menu.on('action:moveAgg', async () => {
        isGraphLoadingStatus.value = true
        menu.remove()
        await moveNodes(dragElement.id, parent.id, false)
        await reloadGraph()
      })
      menu.on('action:moveSub', async () => {
        isGraphLoadingStatus.value = true
        menu.remove()
        await moveNodes(dragElement.id, parent.id, true)
        await reloadGraph()
      })
      menu.on('action:copyAgg', async () => {
        isGraphLoadingStatus.value = true
        menu.remove()
        await copyNodes(dragElement.id, parent.id, false)
        await reloadGraph()
      })
      menu.on('action:copySub', async () => {
        isGraphLoadingStatus.value = true
        menu.remove()
        await copyNodes(dragElement.id, parent.id, true)
        await reloadGraph()
      })
    }

    async function moveNodes(id, parentId, isInheritance) {
      await store.dispatch('domainModel/moveComponent', {
        id,
        parent_id: parentId,
        parent_rel_type: isInheritance ? 'inheritance' : 'aggregation',
      })
      // await store.dispatch('selectEntity2', data.id)
      isGraphLoadingStatus.value = false
    }

    // In place refresh of the view of a single node when edited
    function updateNode(entity) {
      const id = entity?.context?.details?.id
      const cell = graph.getCell(id)
      entity.context.details.labels = entity.context.labels
      const node = transformNode(entity.context.details)
      cell.attr('bodyTextContent/title', node.display_name)
      cell.attr('bodyTextContent/html', util.sanitizeHTML(node.display_name))
      cell.attr('header/text', node.type)
    }

    // Watch for the data of the same selectedEntity2 changing
    // Usually from edits in the sidebar
    watch(
      () => selectedEntity2.value,
      (newNode, oldNode) => {
        if (oldNode && oldNode?.context?.details) {
          if (newNode && newNode?.context?.details) {
            if (newNode.context.details.id === oldNode.context.details.id) {
              updateNode(newNode)
            }
          }
        }
      },
    )

    async function copyNodes(id, parentId, isInheritance) {
      const { data } = await axiosIns.post('/api/v2/domain_model/copy_component', {
        cpt: id,
        parent: parentId,
        parent_rel_type: isInheritance ? 'inheritance' : 'aggregation',
      }, { params: { model: store.state.model.id } })
      await store.dispatch('selectEntity2', data.id)
      isGraphLoadingStatus.value = false
    }

    function transformNode(rawNode) {
      const x = rawNode
      x.type = rawNode.type || 'Unknown'
      if (x.labels) {
        x.type = x.labels.filter(l => l !== 'Component').join()
      }
      x.tags = []
      if (x.abstract && x.abstract === 'True') {
        x.tags.push('abstract')
      } else {
        x.tags.push('not_abstract')
      }
      x.tags.push(x.validity)
      if (x.labels && (x.labels.includes('Function') || x.labels.includes('Capability'))) {
        x.tags.push('fn')
      } else {
        x.tags.push('nfn')
      }
      if (x.parent_rel && x.parent_rel === 'inheritance') {
        x.link_label = 'sub-type'
      } else {
        x.link_label = x.multiplicity
      }
      x.display_name = x.acronym && x.acronym !== '' ? `${x.name} (${x.acronym})` : x.name
      return x
    }

    // Cached counts for performance of the halos to show how many descendant nodes are hidden
    function setDescendantCounts(rootId) {
      console.time('setDescendantCounts')
      function _setDescendantCounts(id) {
        if (nodeMap.value[id]._childCount === 0) {
          return 1
        }
        const count = nodeMap.value[id]._children
          .map(ch => _setDescendantCounts(ch))
          .reduce((a, c) => a + c, 1)
        nodeMap.value[id]._descendantCount = count - 1
        return count
      }

      _setDescendantCounts(rootId)
      console.timeEnd('setDescendantCounts')
    }

    let initialLoad = true
    // Create the nodes with their data and links between them
    async function createGraph() {
      console.time('createGraph')
      const treeData = compositionTree.value
      const nn = []
      // warning: probably very slow TODO, fix the query instead
      const filteredEdges = treeData.edges.filter(e => {
        const msn = treeData.nodes.find(sn => e.source === sn.id)
        const mdn = treeData.nodes.find(dn => e.target === dn.id)
        if (!msn) {
          console.log('source node not available for edge', e)
          return false
        }
        if (!mdn) {
          console.log('target node not available for edge', e)
          return false
        }
        return true
      })
      const targets = filteredEdges.map(e => e.target)
      treeData.nodes.forEach(x => {
        const node = transformNode(x)
        node._childCount = 0
        node._children = []
        nodeMap.value[node.id] = node

        // add root
        if (!targets.includes(node.id)) {
          nn.unshift(node)
        } else {
          nn.push(node)
        }
      })
      const allLinks = filteredEdges.map(e => createLink(e))
      // this is to parse the data from the API into nodes and links, not to create shapes
      links.value = allLinks
      nodes.value = nn
      initialLoad = false
      console.timeEnd('createGraph')
    }

    async function showAllAncestors(id) {
      // Used for when the search or focus finds a node that is currently hidden
      let cur = id
      const ancs = []
      for (let i = 0; i < 1000; i++) {
        if (!nodeMap.value[cur]) {
          break
        }
        if (nodeMap.value[cur] && nodeMap.value[cur].pid == null) {
          break
        }
        ancs.push(cur)
        cur = nodeMap.value[cur].pid
      }
      const newHidden = hiddenNodeIds.value.filter(h => !ancs.includes(h))
      // Only refresh the graph if some nodes need to be unhidden
      if (newHidden.length !== hiddenNodeIds.value.length) {
        hiddenNodeIds.value = newHidden
        await redrawGraph()
      }
    }

    async function loadTree() {
      isGraphLoadingStatus.value = true
      await store.dispatch('domainModel/getClassDiagramData', routeParams.value.focus)
      compositionTree.value = store.state.domainModel.composition_tree
      console.log('classdiagram compositionTree', compositionTree.value)
      searchList.value = compositionTree.value.nodes
      isGraphLoadingStatus.value = false
      await focusOnRouteNode(routeQuery.value.focus)
    }

    async function redrawGraph() {
      isGraphLoadingStatus.value = true
      await createGraph()
      const hidden = hiddenNodeIds.value
      console.time('createShapes')
      const cells = nodes.value
        .filter(n => !hidden?.find(c => c === n.id))
        .map(n => {
          const nodeProperties = [...n.properties, ...n.attributes].map(cn => (
            {
              name: cn,
              visibility: '-',
              type: 'Block',
            }))
          return createClassNode(n, nodeProperties)
        })
      console.timeEnd('createShapes')
      console.time('resetCells')
      paper.freeze()
      // createDemoShapes(graph) // this adds them to the graph
      graph.resetCells([...cells, ...links.value])
      console.timeEnd('resetCells')
      try {
        cells.forEach(c => {
          c.set({
            position: { x: Math.random() * cells.length * 130, y: Math.random() * cells.length * 80 },
          })
        })
      } catch (e) {
        console.log('Couldn\'t layout, no unhidden cells loaded')
      }
      console.time('graphLayout')
      paper.unfreeze()
      // await layoutForceDirected()
      await layoutTree()
      await focusOnRouteNode()
      isGraphLoadingStatus.value = false
    }

    async function layoutTree() {
      const graphLayout = new layout.TreeLayout({
        graph,
        parentGap: 80,
        siblingGap: 80,
        direction: 'B',
      })
      graphLayout.layout()
    }

    async function layoutForceDirected(cells) {
      const graphLayout2 = new layout.ForceDirected({
        graph,
        x: 0,
        y: 0,
        width: cells.length * 130,
        height: cells.length * 80,
        gravityCenter: { x: cells.length * 65, y: 200 },
        charge: 190,
        linkDistance: 1430,
        linkStrength: 2,
      })
      console.timeEnd('graphLayout')
      console.log('graphLayout', graphLayout2)
      graphLayout2.start()
      // Array.from({ length: 100 }).forEach(() => { graphLayout2.step() })
      let steps = 0
      const loopSteps = () => {
        graphLayout2.step()
        steps++
        if (steps < 200) {
          requestAnimationFrame(loopSteps)
        }
      }
      loopSteps()
      requestAnimationFrame(redrawExpandHalos)
    }

    async function reloadGraph() {
      await loadTree()
      await redrawGraph()
    }

    async function refreshClicked() {
      await reloadGraph()
    }

    onMounted(async () => {
      // do we need to append here if the useJointJs is already doing this?
      // ...or is this an override?
      canvas.value.appendChild(scroller.el)
      canvas.value.appendChild(nav.el)
      await reloadGraph()
      scroller.center()
      paper.unfreeze()
      paper.scale(0.85)
      scroller.zoomToFit()
      await focusOnRouteNode(route.value.params.focus)
    })

    // id: a node's UUID
    function isHeader(id) {
      return !!nodeMap.value[id]?.isHeader
    }

    function focusOnHeader(id) {
      const element = graph.getCell(id)
      if (element) {
        haloService.createHalo(element, paper, graph, null, onHideChildren, nodeMap.value[id]._children)
      }
    }

    paper.on('cell:pointerclick', (cellView, evt, x, y) => {
      if (cellView.model.isLink()) return
      if (isHeader(cellView.model.id)) {
        focusOnHeader(cellView.model.id)
        return
      }
      focusNodeById(cellView.model.id, false)
      updateUrlFocus(cellView.model.id)
      context.emit('sidebar', true)
    })
    paper.on('blank:pointerup', evt => {
      // If any nodes were selected using the drag
      if (selection.collection.models.length > 0 && evt.originalEvent.ctrlKey) {
        if (!evt.shiftKey) {
          bulkSelectedNodes.value = []
        }
        selection.collection.models.forEach(cell => {
          bulkSelectedNodes.value.push(cell.id)
        })
        bulkSelectedNodes.value = [...new Set(bulkSelectedNodes.value)]
        if (evt.shiftKey) {
          // UNION mode (add bulk to existing bulk selection)
          selection.collection.reset(graph.getElements().filter(e => bulkSelectedNodes.value.includes(e.id)))
        }
        if (selection.collection.models.length === 1) {
          context.emit('sidebar', true)
        }
      }
    })
    paper.on('cell:pointerdown', (elementView, evt) => {
      if (elementView.model.isLink()) return
      treeLayoutView.startDragging([elementView.model])
    })

    const haloExpandService = new HaloExpandService()

    async function onHideChildren(element) {
      // toggle all children's visibility
      console.time('onHideChildren')
      const children = nodeMap.value[element.id]._children
      const firstChild = children[0]
      if (firstChild) {
        // hide this node's children
        const combined = [...hiddenNodeIds.value, ...children]
        hiddenNodeIds.value = [...new Set(combined)]
        await redrawGraph()
      }
      await focusNodeById(element.id)
      console.timeEnd('onHideChildren')
    }

    async function onShowChildren(element) {
      // toggle all children's visibility
      console.time('onShowChildren')
      const children = nodeMap.value[element.id]._children
      const firstChild = children[0]
      if (firstChild) {
        // show this node's children
        const immediateChildren = nodeMap.value[element.id]._children
        hiddenNodeIds.value = hiddenNodeIds.value.filter(id => !immediateChildren.includes(id))
        await redrawGraph()
      }
      await focusNodeById(element.id)
      console.timeEnd('onShowChildren')
    }

    function redrawExpandHalos() {
      console.time('redrawExpandHalos')
      graph.getElements().forEach(ele => {
        if (nodeMap.value[ele.id]) {
          haloExpandService.createHalo(ele, paper, graph, onShowChildren, nodeMap.value[ele.id]._descendantCount)
        }
      })
      console.timeEnd('redrawExpandHalos')
    }

    const contextToolbarService = new ContextToolbarService()
    let parentElement
    // Context menu event
    paper.on('cell:contextmenu', triggerContextMenu)

    function triggerContextMenu(cellView, evt) {
      if (cellView.model.isLink()) return
      if (isHeader(cellView.model.id)) return
      // eslint-disable-next-line prefer-destructuring
      if (!graph.isSource(cellView.model)) parentElement = graph.getPredecessors(cellView.model)[0]
      else parentElement = null
      // Render the menu
      const contextToolbar = contextToolbarService.createContextMenu(cellView, parentElement, evt.clientX, evt.clientY)
      handleContextActions(contextToolbar)
      document.querySelector('.joint-context-toolbar').addEventListener('contextmenu', evt => {
        // Because JointJS only prevents default on paper elements, it doesn't apply to the context menu that happens
        // to be right under your mouse when you right-click, which just sets off the default event :)
        evt.preventDefault()
      })
    }

    onUnmounted(() => {
      keyboard.off('ctrl+/')
      keyboard.off('ctrl+d')
      keyboard.off('ctrl+a')
    })

    function handleContextActions(contextToolbar) {
      const node = contextToolbarService.contextElement
      contextToolbar.on('action:addChild', async evt => {
        contextToolbar.remove()
        await store.dispatch('domainModel/selectEntity2', node.model.id)
        context.root.$bvModal.show('add-entity-modal')
      })
      contextToolbar.on('action:delete', async evt => {
        contextToolbar.remove()
        await store.dispatch('domainModel/selectEntity2', node.model.id)
        if (Object.values(store.state.model.lookup).find(v => v === node.model.id)) {
          await context.root.$bvModal.msgBoxOk("You can't delete the model lookup nodes", { centered: true })
        } else {
          context.root.$bvModal.show('delete_entity')
        }
      })
      contextToolbar.on('action:addInstance', async evt => {
        contextToolbar.remove()
        await store.dispatch('domainModel/selectEntity2', node.model.id)
        context.root.$bvModal.show('add-instance-modal')
      })
      contextToolbar.on('action:merge', async evt => {
        contextToolbar.remove()
        await store.dispatch('domainModel/selectEntity2', node.model.id)
        context.root.$bvModal.show('merge-entity-modal')
      })
      contextToolbar.on('action:move', async evt => {
        contextToolbar.remove()
        await store.dispatch('domainModel/selectEntity2', node.model.id)
        context.root.$bvModal.show('move-entity-modal')
      })
      contextToolbar.on('action:copy', async evt => {
        contextToolbar.remove()
        await store.dispatch('domainModel/selectEntity2', node.model.id)
        context.root.$bvModal.show('copy-entity-modal')
      })
      contextToolbar.on('action:makeAttr', async evt => {
        contextToolbar.remove()
        await store.dispatch('domainModel/selectEntity2', node.model.id)
        context.root.$bvModal.show('make-entity-attr-modal')
      })
      contextToolbar.on('action:levelUp', async evt => {
        contextToolbar.remove()
        let parentId = nodes.value.find(m => m.id === node.model.id).pid
        if (!parentId) {
          const result = await coreService.componentApi.getComponentParent(node.model.id, route.value.params.modelId)
          parentId = result ? result.id : null
        }
        await reRootOnNode(parentId)
      })
      contextToolbar.on('action:showOnly', async evt => {
        contextToolbar.remove()
        await reRootOnNode(node.model.id)
      })
      contextToolbar.on('action:generateQA', async evt => {
        contextToolbar.remove()
        await store.dispatch('domainModel/selectEntity2', node.model.id)
        context.root.$bvModal.show('generate-qa-requirements-modal')
      })
      contextToolbar.on('action:generateFN', async evt => {
        contextToolbar.remove()
        await store.dispatch('domainModel/selectEntity2', node.model.id)
        context.root.$bvModal.show('generate-fn-requirements-modal')
      })
    }

    async function reRootOnNode(nodeId) {
      await store.dispatch('domainModel/getClassDiagramData', nodeId)
      compositionTree.value = store.state.domainModel.composition_tree
      await redrawGraph()
      await focusNodeById(nodeId)
      updateUrlFocus(nodeId)
    }

    /// Update the focus in the URL silently, should not actually focus the node
    function updateUrlFocus(id) {
      let suffix = ''
      if (!route.value.name.endsWith('_focus')) {
        suffix = '_focus'
      }
      const { href } = router.resolve({
        name: `${route.value.name}${suffix}`,
        params: { ...routeParams.value, focus: id },
      })
      routeParams.value.focus = id
      window.history.pushState({}, null, href)
    }

    /// If the result has an icon, it needs to be loaded from the db, using reRoot
    function onSearchResultClicked(result) {
      if (result.icon) {
        reRootOnNode(result.id)
      } else {
        showAllAncestors(result.id)
        updateUrlFocus(result.id)
        focusNodeById(result.id)
      }
    }

    watch(
      () => routeParams.value,
      newParams => {
        if (compositionTree.value.length < 1) {
          reloadGraph()
        }
        focusOnRouteNode(routeParams.value.focus)
      },
    )

    watch(
      () => routeQuery.value,
      newParams => {
        if (compositionTree.value.length < 1) {
          reloadGraph()
        }
        focusOnRouteNode(routeQuery.value.focus)
      },
    )

    async function focusOnRouteNode(nodeId) {
      if (nodeId) {
        await focusNodeById(nodeId)
      }
    }

    async function focusNodeById(id, zoomTo = true) {
      if (isHeader(id)) {
        focusOnHeader(id)
        return
      }
      let element = graph.getCell(id)
      if (!element) {
        await showAllAncestors(id)
        element = graph.getCell(id)
      }
      if (element) {
        if (id !== selectedEntity2.value) {
          await nextTick()
          await store.dispatch('domainModel/selectEntity2', id)
          await nextTick()
          selection.collection.reset([element])
          bulkSelectedNodes.value = [element]
          haloService.createHalo(element, paper, graph, null, onHideChildren, getChildren(compositionTree.value.edges, id))
        }
        if (zoomTo) {
          scroller.scrollToElement(element, { animation: { duration: 600 } })
        }
      }
    }

    async function doSearch() {
      const results = await coreService.componentApi.findComponents(searchText.value, route.value.params.modelId)
      const unloadedResults = results.filter(r => !nodes.value.find(n => n.id === r.id))
        .map(r => {
          r.icon = 'DatabaseIcon'
          return r
        })
      searchList.value = [...nodes.value, ...unloadedResults]
    }

    function showGenerateFNRequirements() {
      context.root.$bvModal.show('generate-fn-requirements-modal')
      // context.root.$bvModal.show('generate-qa-requirements-modal')
    }
    function showGenerateQARequirements() {
      // context.root.$bvModal.show('generate-fn-requirements-modal')
      context.root.$bvModal.show('generate-qa-requirements-modal')
    }

    async function goHome() {
      route.value.params.root = null
      await loadTree()
      await redrawGraph()
    }

    async function childNodeAdded(data) {
      await loadTree()
      await redrawGraph()
      await focusNodeById(data.id)
    }

    function goToTreeview() {
      router.push({ name: 'ontology_treeview' })
    }

    function exportPerformersReport() {
      const modelId = store.state.model.id
      const modelName = store.state.model.name
      const params = {
        model: modelId,
      }
      // Use datetime to make the file unique
      let now = new Date()
      now = moment(now).format('MMM_DD_HH_MM_SS')
      const filename = `performer_report_${modelName}_${now}.xlsx`
      isGraphLoadingStatus.value = true
      axiosIns.post('/api/v2/domain_model/export_performers_report', {
        model: modelId,
      }, { params, responseType: 'blob' })
        .then(({ data }) => {
          console.log('[Export_Performers_Report::] data returned', data)
          FileSaver.saveAs(data, filename)
          isGraphLoadingStatus.value = false
        })
        .catch(response => {
          console.error(response.data)
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error exporting Performers Report',
              icon: 'AlertIcon',
              variant: 'danger',
              text: `Failed to export Performers Report (${response.data})`,
            },
          })
          this.loading_status = false
        })
    }

    function exportCompositionUML() {
      const modelId = store.state.model.id
      const params = {
        model: modelId,
      }
      // Use datetime to make the file unique
      let now = new Date()
      now = moment(now).format('MMM_DD_HH_MM_SS')
      const filename = `ontology_export_${now}.xmi`
      isGraphLoadingStatus.value = true
      console.log('Root: ', route.value.path.root)
      axiosIns.post('/api/v2/domain_model/export_uml', {
        root: route.value.path.root,
        model: modelId,
      }, { params, responseType: 'blob' })
        .then(({ data }) => {
          console.log('[Export_UML::] data returned', data)
          FileSaver.saveAs(data, filename)
          isGraphLoadingStatus.value = false
        })
        .catch(response => {
          console.error(response.data)
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error exporting Ontology to UML',
              icon: 'CheckIcon',
              variant: 'danger',
              text: `Failed to export Ontology to UML (${response.data})`,
            },
          })
          isGraphLoadingStatus.value = false
        })
    }

    return {
      goToTreeview,
      chart: false,
      canvas,
      canvasClass,
      selectedEntity,
      selectedEntity2,
      dragNode: '',
      dropNode: '',
      selParent: '',
      selNode: '',
      nodes,
      newNode: {},
      layoutIcon: '',
      nodeMap,
      focusNodeById,
      onSearchResultClicked,
      refreshClicked,
      isGraphLoadingStatus,
      reRootOnNode,
      showGenerateQARequirements,
      showGenerateFNRequirements,
      searchList,
      doSearch,
      searchText,
      goHome,
      exportPdf: exporters.pdf,
      exportCompositionUML,
      childNodeAdded,
      exportPerformersReport,
    }
  },
  directives: {
    Ripple,
  },
  props: {
    updateObject: {
      type: Object,
      default: null,
    },
  },
}

</script>

<style scoped lang="scss">
@import "~@clientio/rappid/rappid.css";
@import '~@core/scss/base/plugins/extensions/ext-component-context-menu.scss';

body {
  height: 60vh;
  box-sizing: border-box;
  margin: 0;

  .content-wrapper {
    .content-body {
      width: 100%;
      height: 100%;
    }
  }

  .canvas {
    width: 100%;
    height: 100%;

    .joint-paper {
      border: 1px solid #000000;
    }
  }

}

</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/base/plugins/extensions/ext-component-context-menu.scss';
.canvas-container {
  width: revert;
  max-height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 1rem;
  top: 7rem;
}
.focused rect {
  fill: #16FFFF;
  stroke-width: 2;
}

.selection-box {
  pointer-events: none;
}

.joint-navigator.joint-theme-default {
  top: -200px;
  background-color: rgba(125, 125, 125, 0.3);
  border: none;
  border-top: 2px solid rgba(125, 125, 125, 0.4);
  border-right: 2px solid rgba(125, 125, 125, 0.4);
  border-bottom: 2px solid rgba(125, 125, 125, 0.4);
  border-top-right-radius: 0.428rem;
  border-bottom-right-radius: 0.428rem;
}

.bn-toolbar {
  position: absolute;
  display: flex;
  align-items: normal;
  gap: 1rem;
  left: 1rem;
  z-index: 1;
  height: 2.75rem;
}

.collapse-nodes {
  position: absolute;
  padding: 0.5rem;
  font-weight: bold;
  height: 2.5rem;
  background: black;
  border: 1px solid black;
  border-radius: 1rem;
  left: -0.5rem;
}

.joint-selection.joint-theme-default .selection-wrapper {
  border: 3px dotted #a7a7a7;
}

.joint-halo.joint-theme-default .handle {
  filter: invert(0.9) drop-shadow(1px 1px 2px #222);
}

.joint-selection.joint-theme-default .selection-box {
  border: 3px solid #F8A63B;
}

.joint-type-standard.joint-theme-default.joint-link {
  filter: invert(1) contrast(0.5);
}

.joint-paper.joint-theme-default {
  background-color: rgba(0, 0, 0, 0);
}

.ont-node {
  fill: lightsalmon;
  background: lightsalmon;
}

.ont-header {
  fill: lightblue;
  background: lightblue;
}

.joint-theme-default foreignObject {
  fill: black !important;
  color: black !important;
  text-shadow: 1px 1px 3px white;
}

.joint-theme-default text {
  fill: black !important;
  text-shadow: 1px 1px 3px white;
}

.search-input {
  border-radius: 4px;
  width: 320px;
  background: $body-bg;

  > div {
    height: 2.75rem;
  }
}

.bn-toolbar {
  background: $body-bg;
}

body.dark-layout ~ .joint-context-toolbar.joint-theme-default {
  box-shadow: 0.1rem 0.1rem 1rem black;
  background-color: $theme-dark-body-bg;
  border-color: $theme-dark-border-color;

  .tool {
    background-color: $theme-dark-body-bg;
    border-color: $theme-dark-border-color;
    color: $theme-dark-body-color;
    text-align: left;
    font-family: "Montserrat", Helvetica, Arial, serif;
    font-size: 1rem;
    padding: 1rem;
  }

  .tool:hover {
    background-color: $theme-dark-body-color;
    color: $theme-dark-body-bg;
  }
}

body.dark-layout {
  .search-input {
    background: $theme-dark-body-bg;
  }

  [joint-selector="header"] {
    fill: darken(darkslateblue, 10%);
  }

  [joint-selector="iconsGroup_0"] > image {
    filter: invert(100%)
  }

  .bn-toolbar {
    background: $theme-dark-body-bg;
  }
  .btn-outline-secondary {
    color: #aab0c6;
    border-color: #9ba5b3;
  }

  .class-body-background {
    fill: darkslateblue;
    background: darkslateblue;
    font-size: 72pt;
  }

  .class-link-label-background {
    fill: adjust-hue(darkslateblue, 10deg);
  }

  .ont-header {
    fill: darken(darkslateblue, 20%);
    background: darken(darkslateblue, 20%);
  }

  .joint-theme-default foreignObject {
    fill: lightgrey !important;
    color: lightgrey !important;
    text-shadow: 1px 1px 3px black;
  }
  .joint-theme-default text {
    fill: lightgrey !important;
    text-shadow: 1px 1px 3px black;
  }

  .joint-link:hover > path {
    stroke: lighten($primary, 30%);
  }
}

.joint-context-toolbar.joint-theme-default.joint-vertical .tool:not(:last-child) {
  border-bottom: none;
}

.joint-context-toolbar.joint-theme-default {
  box-shadow: 1rem 1rem 2rem grey;

  .tool {
    background: #f4f4f4;
    text-align: left;
    font-family: "Montserrat", Helvetica, Arial, serif;
    font-size: 1rem;
    padding: 1rem;
  }
}

</style>
