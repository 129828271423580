<template>
  <div v-if="selected_entity2 && selected_entity2.context">
    <b-modal
      id="show-component-modal"
      ref="myref"
      :title="`Component Context`"
      size="xl"
      cancel-title="Close"
      cancel-variant="outline-secondary"
    >
      <h5 class="text-primary">
        {{ selected_entity2.context.details.name }}
      </h5>
      <span
        v-for="(data, index) in entityType"
        :key="index"
        class="mb-25 mr-50"
      >
        <feather-icon
          v-b-tooltip.hover.above="`Type: ${data}`"
          :icon="entityLabel(data)"
          class="font-small-2 mr-50"
        />
        <span class="font-small-2 text-muted font-weight-bolder">{{ data }}</span>
      </span>
      <b-row v-if="selected_entity2.context.details.description">
        <b-col>
          <div v-html="selected_entity2.context.details.description" />
        </b-col>
        <hr class="mb-2">
      </b-row>
      <hr class="mb-2">
      <b-row v-if="selected_entity2.context.details.parent_rel">
        <b-col>
          <b>Stereotype(s):</b> <span class="text-primary">{{ selected_entity2.context.labels.join(" | ") }}</span>
        </b-col>
        <b-col>
          <p v-if="selected_entity2.context.parent_rel.rel_type === 'PART_OF'">
            Is a part of <span class="text-primary">{{ selected_entity2.context.parent_rel.parent_name }}</span>
          </p>
          <p v-else>
            Is a type of <span class="text-primary">{{ selected_entity2.context.parent_rel.parent_name }}</span>
          </p>
        </b-col>
      </b-row>
      <hr class="mb-2">
      <b-row>
        <b-col>
          <blockquote class="text-danger">Relations Graph / Class Diagram / BDD / IBD goes here</blockquote>
        </b-col>
      </b-row>
      <hr class="mb-2">
      <div v-if="selected_entity2.context.labels.includes('Function') || selected_entity2.context.labels.includes('Capability') || selected_entity2.context.labels.includes('Activity')">
        <b-row>
          <b-col>
            <b class="text-primary">
              Performers
            </b>
            <div v-if="selected_performers.length > 0">
              <table>
                <tr v-for="item in selected_performers" :key="item">
                  <td>
                    <span class="text-success">
                      <small>
                        {{ item }}
                      </small>
                    </span>
                  </td>
                </tr>
              </table>
            </div>
            <div v-else>
              <span class="text-danger"><small>No allocated performers</small></span>
            </div>
          </b-col>
          <b-col>
            <b class="text-primary">
              Enablers
            </b>
            <div v-if="selected_enablers.length > 0">
              <table>
                <tr v-for="item in selected_enablers" :key="item">
                  <td>
                    <span class="text-success">
                      <small>
                        {{ item }}
                      </small>
                    </span>
                  </td>
                </tr>
              </table>
            </div>
            <div v-else>
              <span><small>No allocated enablers</small></span>
            </div>
          </b-col>
          <b-col>
            <b class="text-primary">
              Beneficiaries
            </b>
            <div v-if="selected_beneficiaries.length > 0">
              <table>
                <tr v-for="item in selected_beneficiaries" :key="item">
                  <td>
                    <span class="text-success">
                      <small>
                        {{ item }}
                      </small>
                    </span>
                  </td>
                </tr>
              </table>
            </div>
            <div v-else>
              <span><small>No linked beneficiaries</small></span>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b class="text-primary">
              Resources Produced/Consumed/Used
            </b>
            <div v-if="used_resource.length > 0">
              <table>
                <tr v-for="item in used_resource" :key="item">
                  <td>
                    <span class="text-success">
                      <small>
                        {{ item }}
                      </small>
                    </span>
                  </td>
                </tr>
              </table>
            </div>
            <div v-else>
              <span><small>No linked resources</small></span>
            </div>
          </b-col>
        </b-row>
        <hr class="mb-2">
        <b class="text-primary">
          Interfaces
        </b>
        <b-row>
          <b-col>
            <b>Outputs</b>
            <div v-if="outputs.length > 0">
              <table>
                <tr v-for="item in outputs" :key="item">
                  <td>
                    <span class="text-success">
                      <small>
                        {{ item }}
                      </small>
                    </span>
                  </td>
                </tr>
              </table>
            </div>
            <div v-else>
              <span><small>No outputs</small></span>
            </div>
          </b-col>
          <b-col>
            <b>Inputs</b>
            <div v-if="inputs.length > 0">
              <table>
                <tr v-for="item in inputs" :key="item">
                  <td>
                    <span class="text-success">
                      <small>
                        {{ item }}
                      </small>
                    </span>
                  </td>
                </tr>
              </table>
            </div>
            <div v-else>
              <span><small>No inputs</small></span>
            </div>
          </b-col>
        </b-row>
        <hr class="mb-2">
        <b-row>
          <b-col>
            <b class="text-primary">
              Requirements
            </b>
            <div v-if="selected_requirements.length > 0">
              <small>{{ selected_requirements.join(", ") }}</small>
            </div>
            <div v-else>
              <span><small>No allocated requirements</small></span>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-else-if="selected_entity2.context.labels.includes('Performer') || selected_entity2.context.labels.includes('System') || selected_entity2.context.labels.includes('Service') || selected_entity2.context.labels.includes('Node') || selected_entity2.context.labels.includes('HumanActor')">
        <b-row>
          <b-col>
            <b class="text-primary">
              Functions/Activities Performed
            </b>
            <div v-if="selected_performances.length > 0">
              <table>
                <tr v-for="item in selected_performances" :key="item">
                  <td>
                    <span class="text-success">
                      <small>
                        {{ item }}
                      </small>
                    </span>
                  </td>
                </tr>
              </table>
            </div>
            <div v-else>
              <span><small>No functions/activities performed</small></span>
            </div>
          </b-col>
          <b-col>
            <b class="text-primary">
              Enables
            </b>
            <div v-if="enables.length > 0">
              <table>
                <tr v-for="item in enables" :key="item">
                  <td>
                    <span class="text-success">
                      <small>
                        {{ item }}
                      </small>
                    </span>
                  </td>
                </tr>
              </table>
            </div>
            <div v-else>
              <span><small>No functions/activities enabled</small></span>
            </div>
          </b-col>
          <b-col>
            <b class="text-primary">
              Benefits From
            </b>
            <div v-if="benefits_from.length > 0">
              <table>
                <tr v-for="item in benefits_from" :key="item">
                  <td>
                    <span class="text-success">
                      <small>
                        {{ item }}
                      </small>
                    </span>
                  </td>
                </tr>
              </table>
            </div>
            <div v-else>
              <span><small>Benefits from no functions/activities</small></span>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b class="text-primary">
              Other Associations
            </b>
            <div v-if="other_associations.length > 0">
              <table>
                <tr v-for="item in other_associations" :key="item">
                  <td>
                    <span class="text-success">
                      <small>
                        {{ item }}
                      </small>
                    </span>
                  </td>
                </tr>
              </table>
            </div>
            <div v-else>
              <span><small>No other associations</small></span>
            </div>
          </b-col>
        </b-row>
        <hr class="mb-2">
        <b class="text-primary">
          Interfaces
        </b>
        <b-row>
          <b-col>
            <b>Outputs</b>
            <div v-if="outputs.length > 0">
              <table>
                <tr v-for="item in outputs" :key="item">
                  <td>
                    <span class="text-success">
                      <small>
                        {{ item }}
                      </small>
                    </span>
                  </td>
                </tr>
              </table>
            </div>
            <div v-else>
              <span><small>No outputs</small></span>
            </div>
          </b-col>
          <b-col>
            <b>Inputs</b>
            <div v-if="inputs.length > 0">
              <table>
                <tr v-for="item in inputs" :key="item">
                  <td>
                    <span class="text-success">
                      <small>
                        {{ item }}
                      </small>
                    </span>
                  </td>
                </tr>
              </table>
            </div>
            <div v-else>
              <span><small>No inputs</small></span>
            </div>
          </b-col>
        </b-row>
        <hr class="mb-2">
        <b-row>
          <b-col>
            <b class="text-primary">
              Requirements
            </b>
            <div v-if="selected_requirements.length > 0">
              <small>{{ selected_requirements.join(", ") }}</small>
            </div>
            <div v-else>
              <span><small>No allocated requirements</small></span>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <b-row>
          <b-col>
            <b class="text-primary">
              Produced/Consumed/Used in Functions
            </b>
            <div v-if="used_by.length > 0">
              <table>
                <tr v-for="item in used_by" :key="item">
                  <td>
                    <span class="text-success">
                      <small>
                        {{ item }}
                      </small>
                    </span>
                  </td>
                </tr>
              </table>
            </div>
            <div v-else>
              <span><small>Not used by any functions/activities</small></span>
            </div>
          </b-col>
          <b-col>
            <b class="text-primary">
              Exchanged on Interfaces
            </b>
            <div v-if="use_on.length > 0">
              <table>
                <tr v-for="item in use_on" :key="item">
                  <td>
                    <span class="text-success">
                      <small>
                        {{ item }}
                      </small>
                    </span>
                  </td>
                </tr>
              </table>
            </div>
            <div v-else>
              <span><small>Not exchanged on an interface</small></span>
            </div>
          </b-col>
        </b-row>
        <br>
        <b-row>
          <b-col>
            <b class="text-primary">
              Other Associations
            </b>
            <div v-if="other_associations.length > 0">
              <table>
                <tr v-for="item in other_associations" :key="item">
                  <td>
                    <span class="text-success">
                      <small>
                        {{ item }}
                      </small>
                    </span>
                  </td>
                </tr>
              </table>
            </div>
            <div v-else>
              <span><small>No other associations</small></span>
            </div>
          </b-col>
          <b-col>
            <b class="text-primary">
              Properties and Attributes
            </b>
          </b-col>
        </b-row>
        <hr class="mb-2">
        <b-row>
          <b-col>
            <b class="text-primary">
              Requirements
            </b>
            <div v-if="selected_requirements.length > 0">
              <small>{{ selected_requirements.join(", ") }}</small>
            </div>
            <div v-else>
              <span><small>No allocated requirements</small></span>
            </div>
          </b-col>
        </b-row>
      </div>
      <hr class="mb-2">

    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import * as d3 from 'd3'
import { BModal } from 'bootstrap-vue'

export default {
  components: {
    BModal,
  },
  props: {
  },
  data: () => ({
  }),
  computed: {
    ...mapState('domainModel', ['selected_entity2']),
    entityType() {
      return this.selected_entity2.context.labels ? this.selected_entity2.context.labels.filter(e => e !== 'Component') : []
    },
    selected_performers() {
      return this.component ? this.selected_entity2.context.relations.edges.filter(e => e.name === 'Performs').map(x => x.source_str) : []
    },
    selected_performances() {
      return this.selected_entity2 ? this.selected_entity2.context.relations.edges.filter(e => e.name === 'Performs').map(x => x.target_str) : []
    },
    selected_enablers() {
      return this.selected_entity2 ? this.selected_entity2.context.relations.edges.filter(e => e.name === 'Enabled by').map(x => x.target_str) : []
    },
    enables() {
      return this.selected_entity2 ? this.selected_entity2.context.relations.edges.filter(e => e.name === 'Enabled by').map(x => x.source_str) : []
    },
    selected_beneficiaries() {
      return this.selected_entity2 ? this.selected_entity2.context.relations.edges.filter(e => e.name === 'Benefits').map(x => x.target_str) : []
    },
    benefits_from() {
      return this.selected_entity2 ? this.selected_entity2.context.relations.edges.filter(e => e.name === 'Benefits').map(x => x.source_str) : []
    },
    used_resource() {
      return this.selected_entity2 ? this.selected_entity2.context.relations.edges.filter(e => e.name === 'Uses').map(x => x.target_str) : []
    },
    used_by() {
      return this.selected_entity2 ? this.selected_entity2.context.relations.edges.filter(e => e.name === 'Uses').map(x => x.source_str) : []
    },
    other_associations() {
      return this.selected_entity2 ? this.selected_entity2.context.relations.edges.filter(e => !(['Uses', 'Benefits', 'Enabled by', 'Performs'].includes(e.name))).map(x => `${x.source_str}  ${x.name} ${x.target_str}`) : []
    },
    outputs() {
      return this.selected_entity2 ? this.selected_entity2.context.relationships.filter(e => e.labels.includes('Interface') && e.rel_type === 'SOURCE').map(x => x.target_props.name) : []
    },
    inputs() {
      return this.selected_entity2 ? this.selected_entity2.context.relationships.filter(e => e.labels.includes('Interface') && e.rel_type === 'DESTINATION').map(x => x.target_props.name) : []
    },
    use_on() {
      return this.selected_entity2 ? this.selected_entity2.context.relationships.filter(e => e.labels.includes('Interface') && e.rel_type === 'RESOURCE').map(x => x.target_props.name) : []
    },
    selected_requirements() {
      return this.selected_entity2 ? this.selected_entity2.context.relationships.filter(e => e.labels.includes('Requirement')).map(x => x.target_props.display_id) : []
    },
  },
  watch: {
    component(newval) {
      console.log('Switch: ', newval)
    },
  },
  created() { },
  mounted() { },
  updated() { },
  methods: {
    getData() {
      const params = { model: this.$store.state.model.id }
      this.$http
        .get(`/api/v2/domain_model/entity/${this.selected_entity2.context.details.id}`, { params })
        .then(response => {
          this.selected_entity2 = response.data
          console.log('Component details to show: ', this.selected_entity2)
        })
        .catch(r => console.error(r))
    },
    routeToEntity() {
      this.$bvModal
        .msgBoxConfirm('Are you sure you wish to leave this page? Any unsaved changes will be lost.', {
          title: 'Open Entity',
          size: 'sm',
          okVariant: 'success',
          okTitle: 'Open Entity',
          cancelTitle: 'Dismiss',
          cancelVariant: 'outline-danger',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$router.push(
              {
                name: 'domain_ontology_focus',
                params: { focus: this.selected_entity2.id },
              },
            )
          }
        })
    },
    entityLabel(data) {
      // Depending on the label of the Entity, return a different icon
      switch (data) {
      case 'Resource':
        return 'BookOpenIcon'
      case 'Function':
        return 'ZapIcon'
      case 'Performer':
        return 'UserIcon'
      case 'Standard':
        return 'FileTextIcon'
      case 'Reference':
        return 'EyeIcon'
      case 'Agreement':
        return 'PenToolIcon'
      case 'Capability':
        return 'CheckSquareIcon'
      case 'Activity':
        return 'ActivityIcon'
      case 'Service':
        return 'ToolIcon'
      case 'System':
        return 'ServerIcon'
      case 'Objective':
        return 'TargetIcon'
      default:
        return ''
      }
    },
  },
}
</script>

<style lang="scss">
#modal__ShowComponent {
  // b-modal size must be "xl" for this to apply.
  width: 85%;
  margin-left: 7.5%;
  margin-right: 7.5%;
}

.dark-layout {
  #d32DGraph {
    .node text {
      font: 14px sans-serif;
      fill: wheat;
      stroke: rgb(0, 0, 0, 0.2);
      stroke-width: 0.5;
    }
    .link {
      fill: none;
      stroke: rgb(109, 109, 109);
      stroke-width: 2px;
    }
  }
}
#d32DGraph {
  .node circle:hover {
    stroke: wheat;
    stroke-width: 3px;
  }
}

</style>
