import { shapes, util } from '@clientio/rappid';
import { createCustomShape, createXrefLink, XRefLink } from './CustomShapes/customShapeFunctions';
import axiosIns from "@/libs/axios";
import { behaviourNodeStyleDefinitions } from "@/views/Behaviour/JointJSGraph/behaviourNode";
let firstRun = true;
function collapseAbstractNodes(nodes, rootId, hiddenNodeIds, edges, hiddenNodeIdsRef) {
    if (firstRun) {
        firstRun = false;
        nodes.forEach(node => {
            if (node.id !== rootId && node.type === 'FunctionNode') {
                hiddenNodeIds.push(...edges.filter(e => e.source === node.id).map(e => e.target));
            }
        });
        hiddenNodeIdsRef.value = hiddenNodeIds;
    }
}
// eslint-disable-next-line import/prefer-default-export
export function createGraph(graph, nodes, edges, rootId, state, hiddenNodeIdsRef) {
    // Uses custom shape from a file
    const cells = [];
    const hiddenNodeIds = hiddenNodeIdsRef.value;
    collapseAbstractNodes(nodes, rootId, hiddenNodeIds, edges, hiddenNodeIdsRef);
    function walkTree(id) {
        if (hiddenNodeIds?.find(h => h === id))
            return;
        const node = nodes.find(n => n.id === id);
        if (!node)
            return;
        const cell = createCustomShape(node, null, state);
        cells.push(cell);
        edges.filter(e => e.source === id)
            .forEach(e => walkTree(e.target));
    }
    walkTree(rootId);
    const linkArray = [];
    const addLink = (source, target) => {
        linkArray.push(new shapes.standard.Link({ source, target }));
    };
    edges.forEach(e => {
        const source = cells.find(c2 => c2.id === e.source);
        const target = cells.find(c2 => c2.id === e.target);
        if (source && target) {
            addLink(source, target);
        }
    });
    // links are a type of cell, graph.addCells() just wraps .addCell() and both cause redraws
    graph.resetCells([...cells, ...linkArray]);
}
/*
 * Recurse through the BT or ontology data to get the children.
 * tree is null when used by ontology
 */
export function getChildren(edges, tree, nodeId, level = 0, maxLevel = 0) {
    //
    // also
    // can't use the graph.getSuccessors because some might be hidden (and not in the graph)
    if (maxLevel > 0 && level > maxLevel)
        return;
    if (level > 1 && tree?.find(n => n.id === nodeId).type === 'FunctionNode')
        return [];
    const c = edges.filter(e => e.source === nodeId)
        .flatMap(e => getChildren(edges, tree, e.target, level + 1, maxLevel));
    if (level > 0)
        c.unshift(nodeId);
    return c;
}
export function addXrefLink(graph, source, target, colour, label = '') {
    return createXrefLink(graph.getCell(source), graph.getCell(target), colour, label);
}
export function zoomView(paperScroller, evt, x, y, delta) {
    evt.preventDefault();
    paperScroller.zoom(delta * 0.1, {
        min: 0.15, max: 1, grid: 0.1, ox: x, oy: y,
    });
}
export function nodeClickEvent(options) {
    // Handles the click event on any cell/node on the graph
    const { clickedNode, clickEvent, isCtrlKeyPressed, jointSelection, sidebarConditionals, vuexStore, selectedNodesArray } = options;
    if (clickedNode.model.isLink())
        return;
    clickEvent.preventDefault();
    if (clickEvent.ctrlKey) {
        if (jointSelection.collection.models.includes(clickedNode.model)) {
            jointSelection.collection.remove(clickedNode.model);
        }
        else {
            jointSelection.collection.add(clickedNode.model);
        }
    }
    else {
        // Do single click action with no ctrl
        jointSelection.collection.set([clickedNode.model]);
    }
    // Update nodes collection used by sidebars
    selectedNodesArray.value = jointSelection.collection.models.map((selectedGraphNode) => { return selectedGraphNode.id; });
    const count = jointSelection.collection.models.length;
    // Check if no nodes are selected yet
    if (count === 0) {
        closeSidebars(sidebarConditionals);
    }
    else if (count === 1) {
        // Do single actions
        openSelectedBNSidebar(sidebarConditionals, vuexStore, clickedNode.model);
        singleNodeClickActions(clickedNode.model, selectedNodesArray, jointSelection);
    }
    else if (count > 1) {
        // Do bulk actions
        openSidebar(sidebarConditionals);
    }
}
export function openSidebar(sidebarConditionals) {
    const { single, bulk } = sidebarConditionals;
    single.value = !bulk;
    bulk.value = bulk;
}
function closeSidebars(sidebarConditionals) {
    const { single, bulk } = sidebarConditionals;
    single.value = false;
    bulk.value = false;
}
export async function reorderSiblings(store, bnId, parentId, siblingRank) {
    return axiosIns.post(`/api/v2/behaviour/reorder_siblings/${bnId}`, {
        model: store.state.model.id,
        parentId,
        siblingRank,
    });
}
export async function moveNodes(store, dragNode, dropNode, bt_id) {
    return axiosIns.post('/api/v2/behaviour/integrate', {
        model: store.state.model.id,
        source: dragNode,
        target: dropNode,
        source_tree: bt_id,
        rel_type: 'sequence',
    });
}
export async function copyNodes(store, dragNode, dropNode, bt_id, with_children) {
    return axiosIns.post('/api/v2/behaviour/copy_node', {
        bt: bt_id,
        source: dragNode,
        target: dropNode,
        rel_type: 'sequence',
        with_children
    });
}
// ---- Generic Functions Section ----
// For functions that may be reused internally in this file
function singleNodeClickActions(clickedNode, selectedNodesArray, jointSelection) {
    // Deselect any selected nodes
    selectedNodesArray.value = [];
    jointSelection.collection.reset([]);
    // Select the node
    // Make sure clickedNode is already node.model
    selectedNodesArray.value.push(clickedNode.id);
    jointSelection.collection.add(clickedNode);
}
export function createDisplayNameHtml(node) {
    const x = node;
    let result = "";
    // Create a display name for the behaviour
    if (x.type === 'Quantification') {
        if (x.behaviour_name === '||') {
            result = `For each ${x.display_cpt_name} in ${x.set}`;
        }
        else {
            result = `If some ${x.display_cpt_name} in ${x.set}`;
        }
    }
    else if (x.type === 'Quantity') {
        result = `For up to ${x.quantity} of ${x.display_cpt_name} in ${x.set}`;
    }
    else if (x.type === 'Input' || x.type === 'Output') {
        result = x.io_resource_name ? `<p>${x.io_resource_name}</p>` : `<p>${x.io_resource}</p>`;
    }
    else {
        result = (x.negated && (x.negated === 'true' || x.negated === 'True' || x.negated === true)) ? `<p>NOT (<i>${x.behaviour_name})</i>` : `<p><i>${x.behaviour_name}</i>`;
    }
    if (x.type !== 'Quantification') {
        if (x.type === 'Input' || x.type === 'Output') {
            // Create a display name for rel parts
            if (x.rel_parts && x.rel_parts.length > 0) {
                x.rel_parts.forEach(r => {
                    result += `<p class="rel">${r.name}</p>`;
                });
            }
        }
        else {
            if (x.rel_parts && Array.isArray(x.rel_parts)) {
                x.rel_parts.forEach(r => {
                    result += (r && r.preposition !== '') ? ` ${r.preposition}` : '';
                    if (r.instance_name && r.instance_name !== '') {
                        result += ` <b>${r.object_name}</b>#${r.instance_name}`;
                    }
                    else {
                        result += ` <b>${r.object_name}</b>#`;
                    }
                    if (r.attribute && r.attribute !== '') {
                        result += `.${r.attribute}`;
                    }
                });
            }
            result += '</p>';
        }
    }
    return util.sanitizeHTML(result);
}
export function openSelectedBNSidebar(sidebarConditionals, vuexStore, clickedNode) {
    const { single, loading, bulk, } = sidebarConditionals;
    bulk.value = false;
    single.value = true;
    loading.value = true;
    vuexStore.dispatch('behaviours/selectBehaviourNode', clickedNode.id)
        .then(() => {
        loading.value = false;
    });
}
export function focusNode(nodeElement, scroller, selectedNodesArray, jointSelection) {
    // Get node coordinates
    const bbox = nodeElement.getBBox();
    // Animation that scrolls to node center
    scroller.transitionToPoint(bbox.center().x, bbox.center().y, {
        duration: '600ms',
        timingFunction: 'cubic-bezier(0, 0, 0, 1.0)',
    });
    // scroller.scrollToElement(nodeElement, { animation: { duration: 600 }})
    singleNodeClickActions(nodeElement, selectedNodesArray, jointSelection);
}
export function setNodesEnablementData(graph, tree, data) {
    if (data.config !== 'trl' && data.config !== 'status')
        return;
    Object.entries(data.data).forEach(kv => {
        const [id, state] = kv;
        const cell = graph.getCell(id);
        if (cell) {
            const node = tree.nodes.find(c => cell.id === c.id);
            const cssState = state.replace(/\W/g, '');
            node.enablement = cssState;
            const css = buildNodeCss(node);
            cell.attr('body/class', css);
            cell.attr('shape/class', css);
        }
    });
}
export function setNodesTestData(graph, tree, data, results) {
    const tstates = Object.values(data);
    const total = tstates.length;
    const toPct = fn => ((tstates.filter(fn)?.length * 100) / total).toFixed(1);
    results.passPercentage = toPct(k => k === 'Passed');
    results.failPercentage = toPct(k => k === 'Failed');
    results.noRunPercentage = toPct(k => k === 'Inconclusive' || k === 'No Run'
        || k === 'Blocked' || k === 'N/A');
    results.partialPercentage = toPct(k => k === 'Partial');
    results.naPercentage = '0';
    // calculate naPercentage as the remaining percentage after
    // summing all other found percentages
    const sumPctFoundTests = Number(Object.values(results)
        .reduce((r, t) => {
        return (Number(r) + Number(t));
    }, 0));
    results.naPercentage = (100 - sumPctFoundTests).toFixed(1);
    // Set/update testedness properties on nodes based on selected test set
    Object.entries(data).forEach(kv => {
        const [id, testState] = kv;
        const cell = graph.getCell(id);
        if (cell) {
            cell.set('testedness', testState);
            const node = tree.nodes.find(c => cell.id === c.id);
            node.testState = testState;
            const css = buildNodeCss(node);
            cell.attr('body/class', css);
            cell.attr('shape/class', css);
        }
    });
}
export function buildNodeCss(node, style = null) {
    const _style = style || behaviourNodeStyleDefinitions.find(d => d.title === node.type);
    // node type
    let customBodyCss = `${_style?.cssClass}`;
    if (node !== null) {
        // validity
        if (node?.validity)
            customBodyCss += ` bn-${node.validity.split(' ')[0].toLocaleLowerCase()}`;
        // testedness
        if (node?.testState)
            customBodyCss += ' ' + getNodeTestednessCss(node.testState);
        // enablement
        if (node?.enablement)
            customBodyCss += ' bn-' + node.enablement.toLocaleLowerCase();
        // coverage
        if (node?.coverage)
            customBodyCss += ' bn-' + node.coverage.toLocaleLowerCase();
    }
    return customBodyCss;
}
export function getNodeTestednessCss(testState) {
    return `bn-test-${testState.replace(' ', '-').toLocaleLowerCase()}`;
}
export { createXrefLink, XRefLink };
